import React from 'react';
// import BlogLanding from './BlogLanding';

const Blog = () => {
    return (
        <div className='bg-gradient-to-r from-[#92468E] via-[#132C0A] to-[#38235D]  text-white'>

            <h2 className="text-center text-2xl">BLOG IS UPCOMING</h2>

          {/* <BlogLanding></BlogLanding> */}
        </div>
    );
};

export default Blog;